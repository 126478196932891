import { VerkehrszeichenJsonRepo } from './VerkehrszeichenJsonRepo';
import { VerkehrszeichenRepo } from './VerkehrszeichenRepo';

class Repositories {
  private static verkehrszeichenJsonRepoUrl =
    "Ressourcen/Verkehrszeichen/verkehrszeichen.json";
  private static verkehrszeichenRepo: VerkehrszeichenRepo;

  public static verkehrszeichen(): VerkehrszeichenRepo {
    if (Repositories.verkehrszeichenRepo === undefined) {
      Repositories.verkehrszeichenRepo = new VerkehrszeichenJsonRepo(
        this.verkehrszeichenJsonRepoUrl
      );
    }
    return Repositories.verkehrszeichenRepo;
  }
}

export { Repositories };
