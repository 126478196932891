import { TabBar, TabBarItem } from 'antd-mobile/es/components/tab-bar/tab-bar';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { HomeOutlined } from '@ant-design/icons';

import { ILabelAppFooter } from '../modules/Labels';
import { SprachKontext } from '../modules/Sprache/SprachKontext';
import { ThemeContext } from './theme-context';

interface IProps {}

function AppFooter(props: IProps) {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const label: ILabelAppFooter = useContext(SprachKontext).appFooter;

  const tabs = [
    {
      key: "home",
      title: label.startbildschirm,
      icon: <HomeOutlined />,
    },
  ];

  const changeHandler = function (key: string): void {
    switch (key) {
      case "home":
        navigate("/");
        break;
    }
  };

  return (
    <TabBar activeKey={null} onChange={changeHandler}>
      {tabs.map((item) => (
        <TabBarItem
          key={item.key}
          icon={item.icon}
          title={item.title}
          style={{ color: theme.elwisColor }}
        />
      ))}
    </TabBar>
  );
}

export { AppFooter };
