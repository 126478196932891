import { VzKategorie } from '../Vehrkehrszeichen';
import { Verkehrszeichen } from '../Vehrkehrszeichen/Verkehrszeichen';
import { VerkehrszeichenFactory } from '../Vehrkehrszeichen/VerkehrszeichenFactory';
import { VerkehrszeichenRepo } from './VerkehrszeichenRepo';

class VerkehrszeichenJsonRepo implements VerkehrszeichenRepo {
  private jsonUrl: string;
  private repository: Map<string, Verkehrszeichen>;
  private categoryMap: Map<string, VzKategorie>;

  constructor(jsonUrl: string) {
    this.jsonUrl = jsonUrl;
    this.repository = new Map<string, Verkehrszeichen>();
    this.categoryMap = new Map<string, VzKategorie>();
  }

  public fuerSzId(szId: string): Verkehrszeichen {
    const vz = this.repository.get(szId);
    if (vz !== undefined) {
      return vz;
    } else {
      return VerkehrszeichenFactory.unbekanntesVkz(szId);
    }
  }

  public getVerkehrszeichen(): Array<Verkehrszeichen> {
    const vkzArray = Array.from(this.repository.values());
    return vkzArray;
  }

  public getVerkehrszeichenAnzahl(): number {
    return this.repository.size;
  }

  public getVzKategorien(): VzKategorie[] {
    return Array.from(this.categoryMap.values());
  }

  public getVzFuerKategorie(kategorieId: string): Verkehrszeichen[] {
    if (this.categoryMap.has(kategorieId)) {
      const category = this.categoryMap.get(kategorieId) as VzKategorie;
      return category.getEintraege();
    } else {
      return [];
    }
  }

  public async init(): Promise<any> {
    return this.loadJson(this.jsonUrl).then((json) => {
      this.initializeRepo(json);
    });
  }

  private initializeRepo(data: any): void {
    const typMap: Map<string, object> = new Map(Object.entries(data));
    typMap.forEach((kategorien: object, typ: string) => {
      const kategorieMap: Map<string, object> = new Map(
        Object.entries(kategorien)
      );
      kategorieMap.forEach((zeichenListe: object, kategorie: string) => {
        const zeichenMap: Map<string, object> = new Map(
          Object.entries(zeichenListe)
        );
        let vzKategorie: VzKategorie;
        if (zeichenMap.has("katDaten")) {
          vzKategorie = this.vzCategoryFactory(zeichenMap.get("katDaten"));
          this.categoryMap.set(vzKategorie.getId(), vzKategorie);
          zeichenMap.delete("katDaten");
        }
        zeichenMap.forEach((jsonZeichen: object) => {
          const zeichen = VerkehrszeichenFactory.fromJsonObject(
            vzKategorie,
            jsonZeichen
          );
          this.repository.set(zeichen.getId(), zeichen);
          if (vzKategorie !== undefined) {
            vzKategorie.eintrag(zeichen);
          }
        });
      });
    });
  }

  private vzCategoryFactory(category: any): VzKategorie {
    const id = category["id"] as string;
    const position = category["position"];
    const de = category["de"] as string;
    const en = category["en"] as string;
    const vzCategory = new VzKategorie(id, position, de, en);
    return vzCategory;
  }

  private loadJson(url: string): Promise<any> {
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    const conf = {
      method: "GET",
      headers: headers,
    };

    try {
      return fetch(url, conf).then((response) =>
        response.json().then((json) => json)
      );
    } catch (error: any) {
      // Fehlerbehandlung
      console.log(error);
      return Promise.resolve(undefined);
    }
  }
}

export { VerkehrszeichenJsonRepo };
