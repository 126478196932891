import { Divider } from 'antd-mobile';
import { Fragment } from 'react';

interface IProps {}

function BarrierefreiheitDe(props: IProps) {
  return (
    <Fragment>
      <div className="header">
        <h1>Erklärung zur Barrierefreiheit</h1>
        <span className="versionInfo">Stand 19. Dezember 2022</span>
      </div>
      <Divider />
      <div className="content">
        <h2>
          <strong>
            Informationen über die Zugänglichkeit dieser Mobilen Applikation
            gemäß § 12a Behindertengleichstellungsgesetz sowie über
            diesbezügliche Kontaktmöglichkeiten
          </strong>
        </h2>
        <p>
          Die Wasserstraßen- und Schifffahrtsverwaltung des Bundes (
          <abbr title="Wasserstraßen- und Schifffahrtsverwaltung des Bundes">
            WSV
          </abbr>
          ) ist bemüht, die Mobile Applikation des Elektronischen
          Wasserstraßen-Informationsservice (
          <abbr title="Elektronischer Wasserstraßen-Informationsservice">
            ELWISgo
          </abbr>
          ) barrierefrei zugänglich zu machen. Rechtsgrundlagen sind das
          Behindertengleichstellungsgesetz (
          <abbr title="Behindertengleichstellungsgesetz">BBG</abbr>), die
          Barrierefreie Informationstechnik-Verordnung (
          <abbr title="Barrierefreie Informationstechnik-Verordnung">BITV</abbr>{" "}
          2.0) zur Umsetzung der Richtlinie (
          <abbr title="Europäische Union">EU</abbr>) 2016/2102 und die
          harmonisierte europäische Norm{" "}
          <abbr title="Europäische Norm">EN</abbr> 301 549 in ihrer jeweils
          gültigen Fassung.
        </p>
        <Divider />
        <h2>
          <strong>Stand der Vereinbarkeit mit den Anforderungen</strong>
        </h2>
        <p>
          Bei der Entwicklung und Gestaltung, der redaktionellen und technischen
          Konzeption der Moiblen Applikation wurden die Richtlinien der
          Verordnung zur Schaffung barrierefreier Informationstechnik nach dem
          Behindertengleichstellungsgesetz (BITV 2.0) und die harmonisierte
          europäische Norm EN 301 549 in ihrer aktuellen Fassung berücksichtigt.
          Hierbei werden teilweise Kriterien der{" "}
          <span lang="en-GB">
            <abbr title="Web Content Accessibility Guidelines">WCAG</abbr>
          </span>
          -Priorität AAA erfüllt, welche eine bessere digitale barrierefreie
          Zugänglichkeit ermöglichen.
        </p>
        <p>
          Die Einhaltung der Anforderungen wurden im November 2022 für die
          Beta-Version in einem ersten internen Test überprüft. Die Mobile
          Applikation ELWISgo ist derzeit nicht vollständig gemäß den
          technischen Anforderungen der
          Barrierefreie-Informationstechnik-Verordnung (BITV 2.0) vereinbar.
        </p>
        <p>Die Unvereinbarkeiten sind nachstehend aufgeführt.</p>
        <Divider />
        <h2>
          <strong>
            Zurzeit nicht oder eingeschränkt barrierefrei zugängliche Inhalte
          </strong>
        </h2>
        <ul>
          <li>
            Die erforderlichen Kontraste werden bei einigen Überschriften und
            Grafiken für Bedienelemente nicht erreicht.
          </li>
          <li>
            Das Applikationsmenü sowie die darin enthaltenen Radiobuttons als
            Auswahlelemente für die Sprache geben ihren aktuellen Zustand über
            einen Screen-Reader nicht preis.
          </li>
        </ul>
        <Divider />
        <h2>
          <strong>Erstellung dieser Erklärung zur Barrierefreiheit</strong>
        </h2>
        <p>Diese Erklärung wurde am 19. Dezember 2022 erstellt.</p>
        <Divider />
        <h2>
          <strong>
            <span lang="en-GB">Feedback</span> und Kontaktangaben
          </strong>
        </h2>
        <p>
          Wir sind aktuell dabei, die festgestellten Barrieren abzubauen.
          Sollten Sie in der mobilen Applikation dennoch auf Barrieren stoßen,
          senden Sie uns bitte einen entsprechenden Hinweis per{" "}
          <span lang="en-GB">E-Mail</span> mit einer Beschreibung, was Ihnen
          aufgefallen ist. Wir werden unser Möglichstes versuchen, Ihnen die
          Inhalte barrierefrei anzubieten.
        </p>
        <p>Sie können uns auch per Post oder telefonisch kontaktieren:</p>
        <p>
          Generaldirektion Wasserstraßen und Schifffahrt
          <br />
          Abteilung Schifffahrt
          <br />
          Dezernat Grundsätze der Verkehrstechnik
          <br />
          Brucknerstraße 2<br />
          55127 Mainz
        </p>
        <p>
          Projektleitung: Michael Brunsch
          <br />
          E-Mail: info@elwis.de
        </p>
        <Divider />
        <h2>
          <strong>Schlichtungsverfahren</strong>
        </h2>
        <p>
          Beim Beauftragten der Bundesregierung für die Belange von Menschen mit
          Behinderungen gibt es eine Schlichtungsstelle gemäß § 16 BGG. Die
          Schlichtungsstelle hat die Aufgabe, Konflikte zwischen Menschen mit
          Behinderungen und öffentlichen Stellen des Bundes zu lösen.
        </p>
        <p>
          Sie können die Schlichtungsstelle einschalten, wenn Sie mit den
          Antworten aus der oben genannten Kontaktmöglichkeit nicht zufrieden
          sind, mit dem Ziel, mithilfe der Schlichtungsstelle gemeinsam und
          außergerichtlich eine Lösung für ein Problem zu finden.
        </p>
        <p>
          Das Schlichtungsverfahren ist kostenlos. Sie brauchen auch keinen
          Rechtsbeistand.
        </p>
        <p>
          Auf der Internetseite der Schlichtungsstelle (Erreichbarkeit
          nachfolgend) finden Sie alle Informationen zum Schlichtungsverfahren.
          Dort können Sie nachlesen, wie ein Schlichtungsverfahren abläuft und
          wie Sie den Antrag auf Schlichtung stellen.
        </p>
        <p>Die Schlichtungsstelle erreichen Sie wie folgt:</p>
        <p>
          <strong>
            Schlichtungsstelle nach dem Behindertengleichstellungsgesetz
            <br />
          </strong>
          bei dem Beauftragten der Bundesregierung für die Belange von Menschen
          mit Behinderungen
          <br />
          Mauerstraße 53
          <br />
          10117 Berlin
        </p>
        <p>
          Telefon: +49 30 18527-2805
          <br />
          Telefax: +49 30 18527-2901
        </p>
        <p>
          E-Mail: info@schlichtungsstelle-bgg.de
          <br />
          Internet: www.schlichtungsstelle-bgg.de
        </p>
      </div>
    </Fragment>
  );
}

export { BarrierefreiheitDe };
