import { useContext } from 'react';

import { ISprachLabel, SprachKontext } from '../modules/Sprache';
import { DatenschutzDe } from './DatenschutzDe';
import { DatenschutzEn } from './DatenschutzEn';

interface IProps {}

function Datenschutz(props: IProps) {
  const sprache: ISprachLabel = useContext(SprachKontext);
  switch (sprache.sprachId) {
    case "de": {
      return <DatenschutzDe />;
    }
    case "en": {
      return <DatenschutzEn />;
    }
    default: {
      return <DatenschutzDe />;
    }
  }
}

export { Datenschutz };
