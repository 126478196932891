import { Verkehrszeichen } from './Verkehrszeichen';

class VzKategorie {
  static UNBEKANNTE_KATEGORIE = new VzKategorie(
    "unbekannt",
    -1,
    "Unbekannte Kategorie",
    "Unkown category"
  );

  private id: string;
  private position: number;
  private beschreibungDe: string;
  private beschreibungEn: string;
  private eintraege: Map<string, Verkehrszeichen>;

  constructor(
    id: string,
    position: number,
    beschreibungDe: string,
    beschreibungEn: string
  ) {
    this.id = id;
    this.position = position;
    this.beschreibungDe = beschreibungDe;
    this.beschreibungEn = beschreibungEn;
    this.eintraege = new Map<string, Verkehrszeichen>();
  }

  public eintrag(vz: Verkehrszeichen): void {
    this.eintraege.set(vz.getId(), vz);
  }

  public anzahl(): number {
    return this.eintraege.size;
  }

  public getId(): string {
    return this.id;
  }

  public getPosition(): number {
    return this.position;
  }

  public getBeschreibungDe(): string {
    return this.beschreibungDe;
  }

  public getBeschreibungEn(): string {
    return this.beschreibungEn;
  }

  public getEintraege(): Array<Verkehrszeichen> {
    return Array.from(this.eintraege.values());
  }
}

export { VzKategorie };
