import './App.css';

import { Component, MouseEventHandler, useEffect } from 'react';
import {
    BrowserRouter, createSearchParams, NavigateFunction, Route, Routes
} from 'react-router-dom';

import { AppFooter } from './components/AppFooter';
import { AppHeader } from './components/AppHeader';
import { AppMenu } from './components/AppMenu';
import { Barrierefreiheit } from './components/Barrierefreiheit';
import { Datenschutz } from './components/Datenschutz';
import { HinweisDialog } from './components/HinweisDialog';
import { ThemeContext, themes } from './components/theme-context';
import { VzDetails } from './components/VzDetails';
import { VzFilter } from './components/VzFilter';
import { VzListe } from './components/VzListe';
import { AppEinstellungen, IAppEinstellungen } from './modules/Einstellungen';
import { RepoFilter, VerkehrszeichenRepo } from './modules/Repositories';
import { VerkehrszeichenJsonRepo } from './modules/Repositories/VerkehrszeichenJsonRepo';
import { ISprachLabel } from './modules/Sprache';
import {
    SPRACHE_FUER_ID, SPRACHEN_SET_UMSCHALTUNG, SprachId, SprachKontext
} from './modules/Sprache/SprachKontext';
import { Verkehrszeichen, VerkehrszeichenFactory } from './modules/Vehrkehrszeichen';

interface IProps {}

interface IState {
  appVersion: string;
  startHinweis: boolean;
  vzListe: Array<Verkehrszeichen>;
  vzAuswahl: Verkehrszeichen;
  vzRepo: VerkehrszeichenRepo;
  vzFilter: RepoFilter;
  sprache: ISprachLabel;
  landscape: boolean;
  menuIsActive: boolean;
  contentClickHandler: MouseEventHandler<HTMLDivElement>;
}
class App extends Component<IProps, IState> {
  private einstellungen: IAppEinstellungen;
  constructor(props: IProps) {
    super(props);
    this.einstellungen = new AppEinstellungen("einstellungen");
    const repo = new VerkehrszeichenJsonRepo(
      "Ressourcen/Verkehrszeichen/verkehrszeichen.json"
    );
    const sprachEinstellung = SPRACHE_FUER_ID(this.einstellungen.getSprache());
    this.setLangAttribute(sprachEinstellung);
    const filterEinstellung = this.einstellungen.getFilter();
    this.state = {
      appVersion: process.env.REACT_APP_VERSION ?? "k.A.",
      startHinweis: this.einstellungen.getStartHinweis(),
      landscape: window.matchMedia("(orientation:landscape)").matches,
      sprache: sprachEinstellung,
      vzRepo: repo,
      vzAuswahl: VerkehrszeichenFactory.unbekanntesVkz("null"),
      vzFilter: new RepoFilter(repo, filterEinstellung),
      vzListe: new Array(0),
      menuIsActive: false,
      contentClickHandler: this.activeContentClickHandler,
    };
    SPRACHEN_SET_UMSCHALTUNG(this.sprachumschaltung);
  }

  componentDidMount() {
    this.state.vzRepo.init().then(() => {
      this.state.vzFilter.init();
      const vzList = this.state.vzFilter.getVzFiltered();
      this.setState({
        vzListe: vzList,
      });
    });
  }

  toggleMenu = () => {
    const menu = document.getElementById("appMenu");
    const content = document.getElementById("appContent");
    if (menu !== null && content !== null) {
      if (this.state.menuIsActive) {
        this.setState({
          contentClickHandler: this.activeContentClickHandler,
          menuIsActive: false,
        });
      } else {
        this.setState({
          contentClickHandler: this.disabledContentClickHandler,
          menuIsActive: true,
        });
      }
      menu.classList.toggle("show");
      menu.classList.toggle("hide");
    }
  };

  sprachumschaltung = (id: SprachId) => {
    const sprache = SPRACHE_FUER_ID(id);
    this.einstellungen.setSprache(sprache.sprachId);
    this.setState({ sprache: sprache });
    this.setLangAttribute(sprache);
  };

  setLangAttribute = (sprache: ISprachLabel) => {
    let value: string;
    switch (sprache.sprachId) {
      case "de": {
        value = "de-DE";
        break;
      }
      case "en": {
        value = "en-GB";
        break;
      }
      default: {
        value = "de-DE";
        break;
      }
    }
    document.documentElement.setAttribute("lang", value);
  };

  filterChangeHandler = () => {
    this.einstellungen.setFilter(this.state.vzFilter.getActiveFilterIds());
    const vzList = this.state.vzFilter.getVzFiltered();
    this.setState({
      vzListe: vzList,
    });
  };

  disabledContentClickHandler: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    event.stopPropagation();
    this.toggleMenu();
  };

  activeContentClickHandler: MouseEventHandler<HTMLDivElement> = (event) => {};

  hinweisAnzeigeHandler = (anzeigen: boolean) => {
    this.setState({ startHinweis: anzeigen });
  };

  render() {
    return (
      <ThemeContext.Provider value={themes.light}>
        <this.LandscapeEffect />
        <SprachKontext.Provider value={this.state.sprache}>
          <div id="appViewPort">
            {this.state.sprache && (
              <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>
                <HinweisDialog
                  anzeigen={this.state.startHinweis}
                  hinweisAnzeigeHandler={this.hinweisAnzeigeHandler}
                />
                <div id="appHeader">
                  <AppHeader toggleMenu={this.toggleMenu} />
                </div>

                <div id="appMain">
                  <div id="appMenu" className="hide">
                    <AppMenu
                      toggleMenu={this.toggleMenu}
                      hinweisAnzeigeHandler={this.hinweisAnzeigeHandler}
                      appVersion={this.state.appVersion}
                    />
                  </div>
                  <div
                    id="appContent"
                    onClickCapture={this.state.contentClickHandler}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <VzListe
                            vzListe={this.state.vzListe}
                            auswahl={this.state.vzAuswahl}
                            handleAuswahl={this.handleVzAuswahl}
                            repoFilter={this.state.vzFilter}
                          />
                        }
                      />
                      <Route
                        path="verkehrszeichen"
                        element={
                          <VzDetails
                            verkehrszeichenRepo={this.state.vzRepo}
                            landscape={this.state.landscape}
                          />
                        }
                      />
                      <Route
                        path="filter"
                        element={
                          <VzFilter
                            filter={this.state.vzFilter}
                            changeHandler={this.filterChangeHandler}
                          />
                        }
                      />
                      <Route
                        path="barrierefreiheit"
                        element={<Barrierefreiheit />}
                      />
                      <Route path="datenschutz" element={<Datenschutz />} />
                    </Routes>
                  </div>
                </div>
                <div id="appFooter">
                  <AppFooter />
                </div>
              </BrowserRouter>
            )}
          </div>
        </SprachKontext.Provider>
      </ThemeContext.Provider>
    );
  }

  handleVzAuswahl = (vkz: Verkehrszeichen, navigate: NavigateFunction) => {
    const search: string = createSearchParams({
      sz_id: vkz.getId(),
    }).toString();
    navigate({
      pathname: "/verkehrszeichen",
      search: search,
    });
  };

  handleBack = (navigate: NavigateFunction, steps: number = -1) => {
    navigate(steps);
  };

  onWindowResize = () => {
    const landscape: boolean = window.matchMedia(
      "(orientation:landscape)"
    ).matches;
    this.setState({ landscape: landscape });
  };

  LandscapeEffect = () => {
    useEffect(
      () => (
        this.onWindowResize(),
        window.addEventListener("resize", this.onWindowResize),
        () => window.removeEventListener("resize", this.onWindowResize)
      ),
      []
    );
    return null;
  };
}

export default App;
