import { Button, Image, NavBar } from 'antd-mobile';
import { UnorderedListOutline } from 'antd-mobile-icons';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILabelAppHeader } from '../modules/Labels';
import { SprachKontext } from '../modules/Sprache';
import { ThemeContext } from './theme-context';

interface IProps {
  toggleMenu: () => void;
}

function AppHeader(props: IProps) {
  const theme = useContext(ThemeContext);
  const label: ILabelAppHeader = useContext(SprachKontext).appHeader;

  const appLogo = (
    <Image
      src={theme.elwisLogo}
      alt={label.logoAlt}
      height={"30px"}
      fit={"scale-down"}
    />
  );

  const menuIcon = (
    <Button
      type="button"
      shape="rounded"
      size="middle"
      fill="none"
      onClick={props.toggleMenu}
      aria-label={label.einstellungen}
    >
      <UnorderedListOutline
        fontSize={"var(--adm-outline-font-size)"}
        style={{ color: theme.elwisColor }}
      />
    </Button>
  );

  const navigate = useNavigate();
  const backHandler = function () {
    navigate(-1);
  };

  const location = useLocation();
  const showBackArrow = function () {
    return location.pathname !== "/";
  };

  return (
    <NavBar
      back={showBackArrow() ? label.zurueck : null}
      backArrow={showBackArrow()}
      onBack={backHandler}
      right={menuIcon}
      children={appLogo}
      style={{ "--border-bottom": "3px #eee ridge" }}
    />
  );
}

export { AppHeader };
