import { Button, Card, Divider, NavBar } from 'antd-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { ILabelAppMenu } from '../modules/Labels';
import { SprachKontext } from '../modules/Sprache/SprachKontext';
import { SprachUmschalter } from '../modules/Sprache/SprachUmschalter';
import { ThemeContext } from './theme-context';

interface IProps {
  appVersion: string;
  toggleMenu: () => void;
  hinweisAnzeigeHandler: (anzeige: boolean) => void;
}

function AppMenu(props: IProps) {
  const theme = useContext(ThemeContext);
  const label: ILabelAppMenu = useContext(SprachKontext).appMenu;

  const closeIcon = (
    <CloseCircleOutline
      fontSize={"var(--adm-outline-font-size)"}
      style={{ color: theme.elwisColor }}
    />
  );

  const versionString = props.appVersion;

  return (
    <Fragment>
      <NavBar
        backArrow={closeIcon}
        onBack={props.toggleMenu}
        children={label.titel}
      />

      <Card title={label.sprache.titel}>
        <SprachUmschalter />
      </Card>

      <Divider />

      <Button
        style={{ width: "100%" }}
        color="primary"
        onClick={() => {
          props.hinweisAnzeigeHandler(true);
        }}
      >
        {label.hinweisDialog.label}
      </Button>

      <Divider />

      <Link to="/barrierefreiheit">
        <Button
          style={{ width: "100%" }}
          color="primary"
          onClick={() => props.toggleMenu()}
        >
          {label.barrierefreiheit.label}
        </Button>
      </Link>

      <Divider />

      <Link to="/datenschutz">
        <Button
          style={{ width: "100%" }}
          color="primary"
          onClick={() => props.toggleMenu()}
        >
          {label.datenschutz.label}
        </Button>
      </Link>
      <div className="version">
        <span>Version: {versionString}</span>
      </div>
    </Fragment>
  );
}

export { AppMenu };
