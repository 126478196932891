import React from 'react';

export const themes = {
  light: {
    elwisColor: "#589199",
    /*elwisLogo: "elwisgo.png",*/
    elwisLogo: "logos/ELWISgo.svg",
  },
};

export const ThemeContext = React.createContext(themes.light);
