import { useContext } from 'react';

import { ISprachLabel, SprachKontext } from '../modules/Sprache';
import { BarrierefreiheitDe } from './BarrierefreiheitDe';
import { BarrierefreiheitEn } from './BarrierefreiheitEn';

interface IProps {}

function Barrierefreiheit(props: IProps) {
  const sprache: ISprachLabel = useContext(SprachKontext);
  switch (sprache.sprachId) {
    case "de": {
      return <BarrierefreiheitDe />;
    }
    case "en": {
      return <BarrierefreiheitEn />;
    }
    default: {
      return <BarrierefreiheitDe />;
    }
  }
}

export { Barrierefreiheit };
