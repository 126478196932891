import {
    ILabelAppFooter, ILabelAppHeader, ILabelAppMenu, ILabelVerkehrszeichen,
    ILabelVerkehrszeichenListe
} from '../Labels';
import { ILabelVZKategorie } from '../Labels/ILabelVzKategorie';
import { Verkehrszeichen, VzKategorie } from '../Vehrkehrszeichen';
import { ISprachLabel } from './ISprachLabel';
import { SprachId } from './SprachKontext';

class SprachLabel implements ISprachLabel {
  sprachId: SprachId;
  name: string;
  appHeader: ILabelAppHeader;
  appMenu: ILabelAppMenu;
  appFooter: ILabelAppFooter;
  verkehrszeichen: ILabelVerkehrszeichen;
  verkehrszeichenListe: ILabelVerkehrszeichenListe;
  kategorie: ILabelVZKategorie;

  private ress: any;
  vzBeschreibungKurzGetter: () => string;
  vzBeschreibungLangGetter: () => string;
  vzKategorieGetter: () => string;
  katBeschreibungGetter: () => string;

  constructor(ress: any) {
    this.ress = ress;
    this.sprachId = this.ress.sprachId;
    this.name = this.ress.name;
    this.appHeader = this.ress.appHeader;
    this.appMenu = this.ress.appMenu;
    this.appFooter = this.ress.appFooter;
    this.verkehrszeichenListe = this.ress.verkehrszeichenListe;
    this.verkehrszeichen = {
      kategorie: this.vzKategorie,
      beschreibungKurz: this.vzBeschreibungKurz,
      beschreibungLang: this.vzBeschreibungLang,
    };
    this.kategorie = {
      beschreibung: this.katBeschreibung,
    };

    const getterKurz = this.ress.verkehrszeichen.beschreibung.kurz
      .getter as keyof Verkehrszeichen;
    this.vzBeschreibungKurzGetter = Verkehrszeichen.prototype[
      getterKurz
    ] as () => string;

    const getterLang = this.ress.verkehrszeichen.beschreibung.lang
      .getter as keyof Verkehrszeichen;
    this.vzBeschreibungLangGetter = Verkehrszeichen.prototype[
      getterLang
    ] as () => string;

    const getterVzKategorie = this.ress.verkehrszeichen.kategorie
      .getter as keyof Verkehrszeichen;
    this.vzKategorieGetter = Verkehrszeichen.prototype[
      getterVzKategorie
    ] as () => string;

    const getterKatBeschreibung = this.ress.kategorie.beschreibung
      .getter as keyof VzKategorie;
    this.katBeschreibungGetter = VzKategorie.prototype[
      getterKatBeschreibung
    ] as () => string;
  }

  vzKategorie = (zeichen: Verkehrszeichen): string => {
    const kategorie = this.vzKategorieGetter.call(zeichen);
    return kategorie;
  };

  vzBeschreibungKurz = (zeichen: Verkehrszeichen): string => {
    const beschreibung = this.vzBeschreibungKurzGetter.call(zeichen);
    if (beschreibung) {
      return beschreibung;
    } else {
      return this.ress.verkehrszeichen.beschreibung.kurz.unbekannt;
    }
  };

  vzBeschreibungLang = (zeichen: Verkehrszeichen): string => {
    const beschreibung = this.vzBeschreibungLangGetter.call(zeichen);
    if (beschreibung) {
      return beschreibung;
    } else {
      return this.ress.verkehrszeichen.beschreibung.lang.unbekannt;
    }
  };

  katBeschreibung = (kategorie: VzKategorie): string => {
    const beschreibung = this.katBeschreibungGetter.call(kategorie);
    return beschreibung;
  };
}

export { SprachLabel };
