import { Verkehrszeichen, VzKategorie } from '../Vehrkehrszeichen';
import { VerkehrszeichenRepo } from './VerkehrszeichenRepo';

class RepoFilter {
  private repo: VerkehrszeichenRepo;
  private categoryMap: Map<string, VzKategorie>; // Reihenfolge entspricht 'position' Attribut, siehe 'indexCategories()'
  private availableCategoryIds: string[]; // Geordnet nach 'position' Attribut, siehe 'indexCategories()'
  private activeFilterIds: string[];
  private filteredVzMap: Map<string, Verkehrszeichen> = new Map();

  constructor(repo: VerkehrszeichenRepo, presetFilterIds: string[] = []) {
    this.repo = repo;
    this.activeFilterIds = presetFilterIds;

    this.categoryMap = new Map();
    this.availableCategoryIds = [];
  }

  public init() {
    this.indexCategories(this.repo.getVzKategorien());
    this.filteredVzMap = new Map();
    if (this.activeFilterIds.length === 0) {
      this.setAllCategories();
    } else {
      this.applyFilters(this.activeFilterIds);
    }
  }

  public getCategories(): Array<VzKategorie> {
    return Array.from(this.categoryMap.values());
  }

  public getAvailableCount(): number {
    return this.categoryMap.size;
  }

  public getVzFiltered(): Array<Verkehrszeichen> {
    return Array.from(this.filteredVzMap.values());
  }

  public getVzFilteredCount(): number {
    return this.filteredVzMap.size;
  }

  public getVzAllCount(): number {
    return this.repo.getVerkehrszeichenAnzahl();
  }

  public getActiveCount(): number {
    return this.activeFilterIds.length;
  }

  public applyFilters(filterIds: string[]): Verkehrszeichen[] {
    const orderedFilterIds = this.orderFilterIds(filterIds);
    this.filteredVzMap.clear();
    orderedFilterIds.forEach((filterId) => {
      const category = this.categoryMap.get(filterId) as VzKategorie;
      this.addVzList(category.getEintraege());
    });
    this.activeFilterIds = orderedFilterIds;
    return this.getVzFiltered();
  }

  public getActiveFilterIds(): string[] {
    return [...this.activeFilterIds];
  }

  private setAllCategories(): void {
    this.applyFilters(this.availableCategoryIds);
  }

  private addVzList(vzlist: Array<Verkehrszeichen>) {
    vzlist.forEach((vz) => {
      this.filteredVzMap.set(vz.getId(), vz);
    });
  }

  private orderFilterIds(idList: string[]): string[] {
    const ordered = this.availableCategoryIds.filter((id) =>
      idList.includes(id)
    );
    return ordered;
  }

  private indexCategories(categoryList: Array<VzKategorie>): void {
    categoryList.sort((a, b) => (a.getPosition() > b.getPosition() ? 1 : -1));
    categoryList.forEach((category) => {
      this.categoryMap.set(category.getId(), category);
    });
    this.availableCategoryIds = Array.from(this.categoryMap.keys());
  }
}

export { RepoFilter };
