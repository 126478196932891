import React from 'react';

import { SprachId } from '../Sprache';
import { IAppEinstellungen } from './IAppEinstellungen';
import { IAppEinstellungenJson } from './IAppEinstellungenJson';

class AppEinstellungen implements IAppEinstellungen {
  private einstellungen: IAppEinstellungenJson;
  private storageKey: string;
  constructor(storageKey: string) {
    this.storageKey = storageKey;
    const value = window.localStorage.getItem(this.storageKey);
    if (value) {
      // Lade Einstellungen aus LocalStorage
      this.einstellungen = JSON.parse(value) as IAppEinstellungenJson;
    } else {
      // Importiere default Einstellungen aus JSON Datei
      const stdEinstellungen =
        require("./stdEinstellungen.json") as IAppEinstellungenJson;
      this.einstellungen = stdEinstellungen;
      this.speichern();
    }
  }

  getSprache(): SprachId {
    return this.einstellungen.sprache;
  }

  setSprache(id: SprachId): void {
    this.einstellungen.sprache = id;
    this.speichern();
  }

  getFilter(): string[] {
    return this.einstellungen.filter;
  }

  setFilter(filter: string[]): void {
    this.einstellungen.filter = filter;
    this.speichern();
  }

  getStartHinweis(): boolean {
    return this.einstellungen.startHinweis;
  }
  setStartHinweis(anzeigen: boolean): void {
    this.einstellungen.startHinweis = anzeigen;
    this.speichern();
  }

  private speichern() {
    const serialized = JSON.stringify(this.einstellungen);
    try {
      window.localStorage.setItem(this.storageKey, serialized);
    } catch (event) {
      console.log(event);
    }
  }
}

export { AppEinstellungen };
export const EinstellungsKontext = React.createContext<IAppEinstellungen>(
  new AppEinstellungen("einstellungen")
);
