import { Radio, Space } from 'antd-mobile';
import { RadioValue } from 'antd-mobile/es/components/radio';
import { useContext } from 'react';

import { SPRACHEN_AUSWAHL, SPRACHEN_UMSCHALTUNG, SprachId, SprachKontext } from './SprachKontext';

function SprachUmschalter() {
  const sprache = useContext(SprachKontext);
  const sprachumschalter = (value: RadioValue) => {
    const id: SprachId = value as SprachId;
    SPRACHEN_UMSCHALTUNG(id);
  };
  return (
    <Radio.Group defaultValue={sprache.sprachId} onChange={sprachumschalter}>
      <Space direction="vertical">
        {Object.entries(SPRACHEN_AUSWAHL).map(([id, name]) => {
          return <Radio key={id} value={id} children={name} />;
        })}
      </Space>
    </Radio.Group>
  );
}

export { SprachUmschalter };
