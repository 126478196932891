import { Dialog, Switch } from 'antd-mobile';
import { Fragment, useContext } from 'react';

import { EinstellungsKontext, IAppEinstellungen } from '../modules/Einstellungen';
import { SprachKontext } from '../modules/Sprache';

const sprachPropsDe = {
  hinweis: (
    <p>
      "Die Quelle für die Erklärung der Schifffahrtszeichen ist aktuell die
      Broschüre „Sicherheit auf dem Wasser“, die vom Bundesministerium für
      Digitales und Verkehr (BMDV) herausgegeben wird. Feedbacks zur Betaversion
      bitte an <a href="mailto:info@elwis.de">info@elwis.de</a>. Die Betaversion
      1.0 läuft bis Ende April 2023. Anschließend sichten und analysieren wir
      Ihre Feedbacks, um dann im Sommer 2023 die Version 1.0 von ELWISgo
      freizuschalten. Wir halten Sie informiert."
    </p>
  ),
  titel: "Hinweis zur Betaversion",
  actionText: "Hinweis schließen",
  checkedText: "Hinweis weiterhin anzeigen",
  uncheckedText: "Hinweis nicht mehr anzeigen",
};

const sprachPropsEn = {
  hinweis: (
    <p>
      The source for the explanation of the navigation marks is currently the
      Brochure "Sicherheit auf dem Wasser" published by the german Federal
      Ministry for Digital and Transport (BMDV). Please send feedback on the
      beta to <a href="mailto:info@elwis.de">info@elwis.de</a>. The beta version
      1.0 will run until the end of April 2023. We will then view and analyze
      your feedback in order to then release version 1.0 of ELWISgo in summer
      2023. We will keep you informed.
    </p>
  ),
  titel: "Notice on the beta version",
  actionText: "Close notice",
  checkedText: "Keep showing notice",
  uncheckedText: "Don't show notice any more",
};

interface IPropsHinweis {
  sprache: typeof sprachPropsDe;
}

function Hinweis(props: IPropsHinweis) {
  const einstellungen: IAppEinstellungen = useContext(EinstellungsKontext);
  const isChecked = einstellungen.getStartHinweis() ?? true;

  return (
    <Fragment>
      {props.sprache.hinweis}

      <Switch
        style={{ width: "100%" }}
        defaultChecked={isChecked}
        checkedText={props.sprache.checkedText}
        uncheckedText={props.sprache.uncheckedText}
        onChange={(isChecked) => {
          einstellungen.setStartHinweis(isChecked);
        }}
      />
    </Fragment>
  );
}

interface IPropsStartHinweis {
  anzeigen: boolean;
  hinweisAnzeigeHandler: (anzeigen: boolean) => void;
}

function HinweisDialog(props: IPropsStartHinweis) {
  let sprachProps;
  const sprache = useContext(SprachKontext).sprachId;
  switch (sprache) {
    case "de":
      sprachProps = sprachPropsDe;
      break;
    case "en":
      sprachProps = sprachPropsEn;
      break;
    default:
      sprachProps = sprachPropsDe;
  }

  return (
    <Dialog
      style={{ zIndex: 1000 }}
      visible={props.anzeigen}
      title={sprachProps.titel}
      content={<Hinweis sprache={sprachProps} />}
      actions={[
        {
          key: "confirm",
          text: sprachProps.actionText,
          onClick: () => {
            props.hinweisAnzeigeHandler(false);
          },
        },
      ]}
      closeOnAction={true}
    />
  );
}

export { HinweisDialog };
