import React from 'react';

import { ISprachLabel } from './ISprachLabel';
import spracheDeRess from './spracheDe.json';
import spracheEnRess from './spracheEn.json';
import { SprachLabel } from './SprachLabel';

const SPRACHEN_LISTE = {
  de: new SprachLabel(spracheDeRess),
  en: new SprachLabel(spracheEnRess),
};

export type SprachId = keyof typeof SPRACHEN_LISTE;

export const STD_SPRACHE: ISprachLabel = SPRACHEN_LISTE["de"];

type SprachAuswahl = {
  [index in SprachId]: string;
};

export const SPRACHEN_AUSWAHL = {} as SprachAuswahl;

Object.entries(SPRACHEN_LISTE).forEach((prop) => {
  const id: SprachId = prop[0] as SprachId;
  const name: string = prop[1].name;
  SPRACHEN_AUSWAHL[id] = name;
});

export function SPRACHE_FUER_ID(id: SprachId): ISprachLabel {
  if (id in SPRACHEN_LISTE) {
    return SPRACHEN_LISTE[id];
  } else {
    return STD_SPRACHE;
  }
}

type ISprachumschaltung = (sprache: SprachId) => void;

export let SPRACHEN_UMSCHALTUNG: ISprachumschaltung;

export function SPRACHEN_SET_UMSCHALTUNG(fkt: ISprachumschaltung) {
  SPRACHEN_UMSCHALTUNG = fkt;
}

export const SprachKontext = React.createContext<ISprachLabel>(STD_SPRACHE);
