import { Divider } from 'antd-mobile';
import { Fragment } from 'react';

interface IProps {}

function DatenschutzEn(props: IProps) {
  return (
    <Fragment>
      <div className="header">
        <h1>Privacy Statement (German)</h1>
        <span className="versionInfo">Version as of May 2018</span>
      </div>
      <Divider />
      <div lang="de-DE" className="content">
        <p>
          Der <strong>Verantwortliche</strong> im Sinne der
          Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze
          der Mitgliedstaaten sowie sonstiger datenschutzrechtlicher
          Bestimmungen ist die:
        </p>
        <p>
          <strong>
            Generaldirektion Wasserstraßen und Schifffahrt
            <br />
          </strong>
          Herr Dirk Schwardmann
          <br />
          E-Mail: gdws@wsv.bund.de
          <strong>
            <br />
          </strong>
          Am Propsthof 51
          <br />
          53121 Bonn
        </p>
        <p>
          Der <strong>Datenschutzbeauftragte</strong> des Verantwortlichen ist:
        </p>
        <p>
          Herr Jochen Hinz
          <br />
          Generaldirektion Wasserstraßen und Schifffahrt
          <br />
          Am Propsthof 51
          <br />
          53121 Bonn
          <br />
          E-Mail: DSB.GDWS@wsv.bund.de
          <br />
          Telefon: +49 (0) 228 / 7090-3200
        </p>
        <p>
          Bei datenschutzrechtlichen Beschwerden können Sie sich auch an die
          zuständige Aufsichtsbehörde wenden:
          <br />
          <strong>Aufsichtsbehörde</strong>:<br />
          Die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
          <br />
          Husarenstraße 30 <br />
          53117 Bonn
        </p>
        <p>
          Die Generaldirektion Wasserstraßen und Schifffahrt (
          <abbr title="Generaldirektion Wasserstraßen und Schifffahrt">
            GDWS
          </abbr>
          ) respektiert Ihre Privatsphäre und fühlt sich daher dem Datenschutz
          und der Informationsfreiheit verpflichtet. Dies gilt natürlich auch
          für Ihren Besuch auf unserer Website.
        </p>
        <p>
          Wir geben diese Datenschutzerklärung ab, um Sie über unsere
          Datenschutzbestimmungen und -maßnahmen zu informieren, insbesondere
          darüber, was wir zum Schutz Ihrer Daten unternehmen und welche Daten
          wir überhaupt erheben.
        </p>
        <p>
          Wir verpflichten uns, die Daten der Besucher unserer Website zu
          schützen. Dies gilt auch für externe Dienstleister im Rahmen ihrer für
          die GDWS erledigten Aufgaben.
        </p>
        <Divider />
        <h2>
          <strong>Erheben und Verarbeiten personenbezogener Daten</strong>
        </h2>
        <p>
          Bei Ihrer Nutzung der mobilen Anwendung{" "}
          <abbr title="Elektronischer Wasserstraßen-Informationsservice">
            ELWISgo
          </abbr>{" "}
          werden auf unseren Webservern in einer Protokolldatei Daten, die
          möglicherweise eine Identifizierung zulassen, aus Gründen der
          technischen Sicherheit, insbesondere zur Abwehr von Angriffsversuchen,
          für einen begrenzten Zeitraum gespeichert. Dabei handelt es sich um
          folgende Angaben:
        </p>
        <ul>
          <li>
            <abbr title="Internet Protokoll">IP</abbr>-Adresse
          </li>
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>betrachtete Seiten</li>
          <li>aktuell aufgerufenes Dokument</li>
          <li>
            <span lang="en-GB">
              <abbr title="Hypertext Transfer Protocol Secure">HTTPS</abbr>
            </span>
            -Statuscode
          </li>
        </ul>
        <p>
          Die gespeicherten Daten werden außerdem für statistische Zwecke
          ausgewertet. Eine andere Verwendung oder Weitergabe an Dritte findet
          nicht statt.
        </p>
        <p>
          Rechtsgrundlage für die vorübergehende Speicherung der Daten ist
          Artikel 6 Absatz 1{" "}
          <span lang="la">
            <abbr title="litera">lit.</abbr>
          </span>{" "}
          f Datenschutz-Grundverordnung (
          <abbr title="Datenschutz-Grundverordnung">DSGVO</abbr>).
        </p>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks
          ihrer Erhebung nicht mehr erforderlich sind.
        </p>
        <p>
          Wir behalten uns das Recht vor, im Falle von schweren Verstößen gegen
          unsere Nutzungsbedingungen und bei unzulässigen Zugriffen
          beziehungsweise Zugriffsversuchen auf unsere Server unter Zuhilfenahme
          einzelner Datensätze eine Herleitung zu personenbezogenen Daten zu
          veranlassen.
          <strong>
            {" "}
            <br />
          </strong>
        </p>
        <Divider />
        <h2>
          <strong>Änderungen dieser Erklärung</strong>
        </h2>
        <p>
          Sollte es Aktualisierungen der Bedingungen der ELWISgo
          Datenschutzerklärung der GDWS geben, stellen wir diese Änderungen
          bereit und aktualisieren das Änderungsdatum dieses Dokuments
          entsprechend. Auf diese Weise wissen Sie zu jeder Zeit, welche Daten
          wir <span lang="en-GB">online</span> erfassen, wie diese verwendet
          werden und welche Optionen Ihnen zur Verfügung stehen.
        </p>
        <Divider />
        <h2>
          <strong>Betroffenenrechte</strong>
        </h2>
        <p>
          Sie haben nach der DSGVO folgende Rechte hinsichtlich der Sie
          betreffenden personenbezogenen Daten:
        </p>
        <ul>
          <li>Recht auf Auskunft,</li>
          <li>Recht auf Berichtigung,</li>
          <li>Recht auf Löschung,</li>
          <li>Recht auf Einschränkung der Verarbeitung,</li>
          <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
          <li>Recht auf Datenübertragbarkeit.</li>
        </ul>
        <p>
          Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
          über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren.
        </p>
      </div>
    </Fragment>
  );
}

export { DatenschutzEn };
